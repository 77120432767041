import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "digital-clothing"
};
import { onBeforeUnmount, onMounted, ref } from "vue";
import DigitalClothingBanner from "@/views/solution/digital-clothing-module/digital-clothing-banner.vue";
import DigitalVolume from "@/views/solution/digital-clothing-module/digital-volume.vue";
import ContactUs from "@/views/solution/digital-clothing-module/contact-us.vue";
import ApplicationExperienceModal from "@/views/solution/digital-custom-module/application-experience-modal.vue";
export default {
  __name: 'digital-clothing',
  setup: function setup(__props) {
    var isShowContactModal = ref(false);
    var openApplication = function openApplication() {
      isShowContactModal.value = true;
    };
    var clsoeModal = function clsoeModal() {
      isShowContactModal.value = false;
    };
    return function (_ctx, _cache) {
      var _component_n_drawer = _resolveComponent("n-drawer");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(DigitalClothingBanner, {
        onOpenApplication: openApplication
      }), _createVNode(DigitalVolume), _createVNode(ContactUs, {
        type: "outfit",
        img: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/06/solution/contus-1.png"
      }), _createVNode(_component_n_drawer, {
        show: _unref(isShowContactModal),
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(isShowContactModal) ? isShowContactModal.value = $event : isShowContactModal = $event;
        }),
        "close-on-esc": false,
        "mask-closable": false,
        width: 810,
        placement: "left"
      }, {
        default: _withCtx(function () {
          return [_createVNode(ApplicationExperienceModal, {
            onClose: clsoeModal
          })];
        }),
        _: 1
      }, 8, ["show"])]);
    };
  }
};