import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3c1ab0df"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "digital-clothing-describe"
};
var _hoisted_2 = {
  class: "digital-clothing-title-text digital-clothing-title"
};
var _hoisted_3 = {
  class: "digital-clothing-title-text customized-service"
};
var _hoisted_4 = {
  class: "digital-clothing-desc-text"
};
var _hoisted_5 = {
  class: "clothing-gif"
};
import { ref, onMounted } from "vue";
// import scrollTextGrading from "@/utils/scroll-text-gradient"
import UniVideo from "@/uni-ui/uni-video/index.vue";
export default {
  __name: 'digital-clothing-banner',
  emits: ["openApplication"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var screenHeight = ref(0);
    var uniVideoHome = ref(null);
    screenHeight.value = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 80;
    var openApplication = function openApplication() {
      emits("openApplication");
      window.onresize = function () {
        return function () {
          screenHeight.value = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 80;
        }();
      };
    };

    // onMounted(() => {
    // const scrollTextGradingChange = new scrollTextGrading()
    // scrollTextGradingChange.init(".reveal-type",300,200)
    // scrollTextGradingChange.init(".reveal-type2",320,200)
    // })

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "digital-clothing-banner",
        style: _normalizeStyle({
          height: _unref(screenHeight) + 'px'
        })
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("digitalClothing.digitalClothingTitle")), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("digitalClothing.customizedService")), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("digitalClothing.fullScene")), 1), _createElementVNode("div", {
        class: "digital-clothing-btn flex-center effect-btn",
        onClick: openApplication
      }, _toDisplayString(_ctx.$t("digitalClothing.applicationExperience")), 1), _createElementVNode("div", _hoisted_5, [_createVNode(UniVideo, {
        class: "uni-video-home",
        ref_key: "uniVideoHome",
        ref: uniVideoHome,
        elementId: "home-video",
        poster: "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/07/public/home-poster.jpg",
        "video-url": "https://appletfujiunicom.oss-cn-shenzhen.aliyuncs.com/attach/2023/12/video/walking.m3u8"
      }, null, 512)])])], 4);
    };
  }
};